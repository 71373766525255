@font-face {
  font-family: 'Nauman';
  src: url('../src/resources/fonts/Nauman-HeavyItalic.otf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F1F4F6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
}

.Layout {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .css-wjwmnl-MuiButtonBase-root-MuiTab-root {
    flex-basis: 100% !important;
  }
}

.slick-slider {
  height: 90%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
}

.eeZNoi {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
}